import React, { useState } from "react"
import styled from "styled-components"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"
import { Link } from "gatsby"

const SubmenuContainer = styled.div`
  position: absolute;
  top: calc(100% - 1.5rem);
  left: 0;
  z-index: -1;

  display: flex;
  justify-content: center;

  margin: 0;
  padding: 2rem;
  width: 100%;
  background-color: #fff;

  transform: scaleY(0);
  transform-origin: top;

  transition: transform 0.3s ease;

  @media (max-width: 1280px) {
    transform: scaleY(100%);
    position: static;
    padding: 0;
    overflow: hidden;
  }
`

const SubmenuInner = styled.ul`
  list-style: none;

  margin-right: 500px;
  min-height: 220px;
  max-height: 220px;

  display: flex;
  justify-content: center;

  @media (max-width: 1920px) {
    margin-right: 180px;
  }

  @media (max-width: 1600px) {
    margin: 0;
  }

  @media (max-width: 1280px) {
    min-height: 0;
    max-height: 0;
  }
`

const ListItem = styled.li`
  position: relative;
  margin-bottom: 24px;
  > a {
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.primary};
  }

  &::before {
    content: "";

    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);

    width: 9px;
    height: 9px;
    background-color: ${(props) => props.theme.secondary};
  }

  &:hover > a {
    color: ${(props) => props.theme.secondary};
  }
`

const Links = styled.div`
  margin-right: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1280px) {
    align-items: center;
    margin: 0;
  }
`

const LinkContent = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1280px) {
    display: none;
  }
`

const LinkContentLogo = styled.div`
  max-width: 13rem;
  min-width: 13rem;
  margin-right: 4rem;
`

const LinkContentDescription = styled.div`
  max-width: 310px;
  font-size: 16px;
`

const MoreButton = styled.span`
  background: none;
  border: none;
  position: relative;
  color: #fff;
  padding: 0 5px;
  cursor: pointer;
  :focus {
    outline: none;
    ::before {
      border-color: ${(props) => props.theme.secondary};
    }
  }
  ::before {
    content: "";
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-bottom: 2px ${(props) => props.theme.primary} solid;
    border-left: 2px ${(props) => props.theme.primary} solid;
    transform: translate(-50%, -70%) rotate(-45deg);
    position: absolute;
  }
`

const HeaderSubmenu = ({ menuItems }) => {
  const [elementData, setElementData] = useState(menuItems[0])
  const isItemContainImage = !!elementData.thumbnail.url

  const isItemContainDescription = !!elementData.description

  const handleSubmenu = (index) => {
    const currentElementData = menuItems[index]
    setElementData(currentElementData)
  }

  return (
    <>
      <MoreButton aria-label="Open submenu">{">"}</MoreButton>
      <SubmenuContainer className="header-submenu">
        <SubmenuInner>
          <Links>
            {menuItems.map((menuItem, index) => (
              <ListItem
                key={menuItem.title}
                onMouseOver={() => handleSubmenu(index)}
              >
                <Link to={menuItem.url.replace(/http.*\/\/.*?\//g, "/")}>
                  {decodeHtmlCharCodes(menuItem.title)}
                </Link>
              </ListItem>
            ))}
          </Links>
          <LinkContent>
            <LinkContentLogo>
              {isItemContainImage ? (
                <img
                  src={elementData.thumbnail.url.link}
                  alt={elementData.thumbnail.alt}
                />
              ) : null}
            </LinkContentLogo>
            <LinkContentDescription>
              {isItemContainDescription ? (
                <p>{elementData.description}</p>
              ) : null}
            </LinkContentDescription>
          </LinkContent>
        </SubmenuInner>
      </SubmenuContainer>
    </>
  )
}

export default HeaderSubmenu
