/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const filteredMeta = meta.map((metaTag) => {
    const newMeta = {
      content: metaTag.normalized_content ?? metaTag.content.source_url,
    }
    if (metaTag.property !== null) {
      newMeta.property = metaTag.property
    }
    if (metaTag.name !== null) {
      newMeta.name = metaTag.name
    }
    return newMeta
  })
  const metaDescription =
    meta.find((metaTag) => metaTag.property === "og:description")
      ?.normalized_content ||
    description ||
    site.siteMetadata.description

  const metaTitle =
    meta.find((metaTag) => metaTag.property === "og:title")
      ?.normalized_content || title

  const metaUrl =
    url ||
    meta
      .find((metaTag) => metaTag.property === "og:url")
      ?.normalized_content.replace("admin.", "")

  const metaImage =
    url ||
    meta.find((metaTag) => metaTag.property === "og:image")?.normalized_content

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        { name: `og:title`, content: metaTitle },
        { name: `og:url`, content: metaUrl },
        {
          name: `og:image`,
          content:
            "https://admin.blueowl.pl/wp-content/uploads/2022/02/logo_blueowl.png",
        },
      ].concat(filteredMeta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
